export default {
    install(Vue) {
        Vue.prototype.checkChar = function (value) { //禁止输入特殊字符
            value = value.replace(
                    /[`*!@#$%^&*()_+=<>?:"{}|,./;'\\[\]·！@#￥%……&*（）——+={}|《》？：“”【】、；‘’，。、]/g,
                    ""
                )
                .replace(/\s/g, "");
            return value;
        };

        Vue.prototype.formateTime = function (value) { //转成毫秒
            let rsp = value.replace(new RegExp("-", "gm"), ",");
            return new Date(rsp).getTime();
        };
        Vue.prototype.setToDate = function (value) { //到日
            return value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
        };
        Vue.prototype.setToMin = function (value) { //到分
            return value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate() + " " + value.getHours() + ":" + value.getMinutes();
        };
        Vue.prototype.endOneYear = function () {
            //今天之前,一年之后不可选
            return {
                disabledDate(time) {
                    let start = new Date().setDate(new Date().getDate() - 1);
                    let end = new Date().setDate(new Date().getDate() + 365);
                    return (time.getTime() < start) | (time.getTime() > end);
                },
                shortcuts: [{
                        text: "30天",
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
                            picker.$emit("pick", date);
                        },
                    },
                    {
                        text: "60天",
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 60);
                            picker.$emit("pick", date);
                        },
                    },
                    {
                        text: "90天",
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 90);
                            picker.$emit("pick", date);
                        },
                    },
                    {
                        text: "180天",
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 180);
                            picker.$emit("pick", date);
                        },
                    },
                    {
                        text: "一年",
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 365);
                            picker.$emit("pick", date);
                        },
                    },
                ],
            };
        };
    }
};