import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import "./assets/css/index.css";
import VueLazyload from 'vue-lazyload'
import store from './store'
import commonjs from './assets/common';
import axios from 'axios'

Vue.prototype.$axios = axios 
Vue.use(VueLazyload)
Vue.config.productionTip = false
Vue.use(commonjs);

// VueAMap.initAMapApiLoader({
//   // 高德的key
//   key: 'd4cf094c72d98c7b81716b8e6741e4b9',
//   // 插件集合
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
//   // 高德 sdk 版本，默认为 1.4.4
//   v: '1.4.4'
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')