import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    biz: '',
  },
  mutations: {
    biz(state, payLoad) {
      state.biz = payLoad;
      localStorage.setItem('biz', JSON.stringify(payLoad));
    },

  },
  actions: {
    setBiz(context, payLoad) {
      context.commit('biz', payLoad);
    },
  },
  getters: {
    getBiz(state) {
      state.biz = JSON.parse(localStorage.getItem('biz'));
      return state.biz;
    },
  },
  modules: {}
});